/* eslint-disable */
// https://dev2.bhsn.it/api/aireview/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'aireview';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * Edit Clause v1
 * @returns AireviewResponseEditClauseDto OK
 * @throws ApiError
 */
export function postEditClauseV1(
    {
        requestBody,
    }: {
        requestBody: AireviewEditClauseForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseEditClauseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/edit-clause/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Review Chat SSE(Server-Sent Events) 방식.
 * @returns AireviewSseEmitter 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postReviewChatV11(
    {
        requestBody,
    }: {
        requestBody: AireviewReviewChatForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewSseEmitter> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-chat/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 쿼리 컨펌 v2
 * @returns AireviewResponseQueryConfirmDto OK
 * @throws ApiError
 */
export function postCheckpointV2(
    {
        requestBody,
    }: {
        requestBody: AireviewQueryConfirmForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseQueryConfirmDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-chat/query-confirm/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Review Clause Analysis SSE(Server-Sent Events) 방식.
 * @returns AireviewSseEmitter 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postReviewChatV1(
    {
        requestBody,
    }: {
        requestBody: AireviewReviewClauseAnalysisForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewSseEmitter> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-clause-analysis/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 파일 리뷰 v2
 * @returns AireviewResponseReviewDto OK
 * @throws ApiError
 */
export function reviewContractFileV2(
    {
        formData,
    }: {
        formData?: {
            form: AireviewFileReviewFormV2;
            /**
             * 계약서 파일
             */
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-file/v2',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 파일 리뷰 v1
 * @returns AireviewResponseReviewDto OK
 * @throws ApiError
 */
export function reviewContractFileV1(
    {
        formData,
    }: {
        formData?: {
            form: AireviewFileReviewForm;
            /**
             * 계약서 파일
             */
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-file/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v4
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentV4(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewFormV4;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v4',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v3
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentV3(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v2
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentV2(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v1
 * @returns AireviewResponseReviewDto OK
 * @throws ApiError
 */
export function reviewContractDocumentV1(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 Cache v2
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentByCacheV2(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewCacheFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/by-cache/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 Cache v1
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentByCacheV1(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewCacheForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/by-cache/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체크포인트 v4 (체크포인트별 결과)
 * @returns AireviewResponseListCheckClauseResultDto OK
 * @throws ApiError
 */
export function postCheckpointV4(
    {
        requestBody,
    }: {
        requestBody: AireviewCheckpointFormV4;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListCheckClauseResultDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/checkpoints/v4',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체크포인트 v3 (체크포인트별 결과)
 * @returns AireviewResponseListCheckClauseResultDto OK
 * @throws ApiError
 */
export function postCheckpointV3(
    {
        requestBody,
    }: {
        requestBody: AireviewCheckpointFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListCheckClauseResultDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/checkpoints/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 체크포인트 v2
 * @returns AireviewResponseCheckResult OK
 * @throws ApiError
 */
export function postCheckpointV21(
    {
        requestBody,
    }: {
        requestBody: AireviewCheckpointFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseCheckResult> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/checkpoints/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 번역 SSE(Server-Sent Events) 방식.
 * @returns AireviewSseEmitter 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postTranslationV1(
    {
        requestBody,
    }: {
        requestBody: AireviewTranslationForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewSseEmitter> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/translations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 기본 정보 추출 v3
 * @returns AireviewResponseContractMetadataDtoV2 OK
 * @throws ApiError
 */
export function predictContractV3(
    {
        requestBody,
    }: {
        requestBody: AireviewContractPredictForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseContractMetadataDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contract-prediction/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 기본 정보 추출 v2
 * @returns AireviewResponseContractMetadataDtoV2 OK
 * @throws ApiError
 */
export function predictContractV2(
    {
        requestBody,
    }: {
        requestBody: AireviewContractPredictForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseContractMetadataDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contract-prediction/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 기본 정보 추출
 * @returns AireviewResponseContractMetadataDto OK
 * @throws ApiError
 */
export function predictContract(
    {
        requestBody,
    }: {
        requestBody: AireviewContractPredictForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseContractMetadataDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contract-prediction/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 유사 조항 목록
 * @returns AireviewResponseListString OK
 * @throws ApiError
 */
export function getSimilarClauses(
    {
        requestBody,
    }: {
        requestBody: AireviewSimilarClauseCondition;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/similar-clauses/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 유형 목록
 * @returns AireviewResponseListContractTypeDto OK
 * @throws ApiError
 */
export function contractTypesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contract-types/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 유형 목록
 * @returns AireviewResponseListContractTypeDto OK
 * @throws ApiError
 */
export function contractTypesV1(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contract-types/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 코드 목록
 * @returns AireviewResponseMapStringListEnumDto OK
 * @throws ApiError
 */
export function codesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseMapStringListEnumDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/codes/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 코드 목록
 * @returns AireviewResponseMapStringListEnumDto OK
 * @throws ApiError
 */
export function codesV1(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseMapStringListEnumDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(_?: any, requestConfig?: RequestConfig): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
/**
 * 라벨 체크포인트 목록 V2
 * @returns AireviewResponseListLabelCheckpointDto OK
 * @throws ApiError
 */
export function getLabelCheckpoints(
    {
        contractType,
        language,
        labelIds,
        includeClauseExamples,
    }: {
        /**
         * 계약 타입
         */
        contractType: string;
        /**
         * 언어
         */
        language: 'KO' | 'EN' | 'ETC';
        /**
         * 라벨 IDs
         */
        labelIds?: Array<number>;
        /**
         * 예시 조항 포함 여부
         */
        includeClauseExamples?: boolean;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListLabelCheckpointDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/label-checkpoints/v2',
                query: {
                    labelIds: labelIds,
                    includeClauseExamples: includeClauseExamples,
                    contractType: contractType,
                    language: language,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 라벨 체크포인트 목록
 * @returns AireviewResponseListLabelCheckpointDto OK
 * @throws ApiError
 */
export function getLabelCheckpoints1(
    {
        contractType,
        language,
        labelIds,
        includeClauseExamples,
    }: {
        /**
         * 계약 타입
         */
        contractType: string;
        /**
         * 언어
         */
        language: 'KO' | 'EN' | 'ETC';
        /**
         * 라벨 IDs
         */
        labelIds?: Array<number>;
        /**
         * 예시 조항 포함 여부
         */
        includeClauseExamples?: boolean;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListLabelCheckpointDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/label-checkpoints/v1',
                query: {
                    labelIds: labelIds,
                    includeClauseExamples: includeClauseExamples,
                    contractType: contractType,
                    language: language,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내에 Contract Type 목록
 * @returns AireviewResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getWorkspaceContractTypesV21(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-contract-types/v2',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace Contract Type 업데이트
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function putWorkspaceContractTypesV2(
    {
        requestBody,
    }: {
        requestBody: AireviewWorkspaceContractTypeFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-contract-types/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내에 Contract Type 목록
 * @returns AireviewResponseListContractTypeVo OK
 * @throws ApiError
 */
export function getWorkspaceContractTypes1(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListContractTypeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-contract-types/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace Contract Type 업데이트
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function putWorkspaceContractTypes(
    {
        requestBody,
    }: {
        requestBody: AireviewWorkspaceContractTypeForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-contract-types/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내 AI Provider Config 목록
 * @returns AireviewResponseListWorkspaceAiServiceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceAiServiceConfigs(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListWorkspaceAiServiceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-ai-service-configs/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내 AI Provider Config 업데이트
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function putWorkspaceAiServiceConfigs(
    {
        requestBody,
    }: {
        requestBody: AireviewWorkspaceAiServiceConfigUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-ai-service-configs/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내 AI Provider Config 목록
 * @returns AireviewResponseListWorkspaceAiServiceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceAiProviderConfigs(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListWorkspaceAiServiceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-ai-provider-configs/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내 AI Provider Config 업데이트
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function putWorkspaceAiProviderConfigs(
    {
        requestBody,
    }: {
        requestBody: AireviewWorkspaceAiProviderConfigUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-ai-provider-configs/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 조항 예시 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadClauseExampleExcel(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/clause-examples/excel/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 조항 예시 엑셀 업로드
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function updateClauseExamplesByExcel(
    {
        workspaceId,
        formData,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/clause-examples/excel/v1',
                query: {
                    workspaceId: workspaceId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스별 라벨 체크포인트 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadLabelCheckpointsExcel(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/label-checkpoints/workspace/{workspaceId}/excel/v1',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스별 라벨 체크포인트 엑셀 업로드
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function uploadLabelCheckpointsExcel(
    {
        workspaceId,
        formData,
    }: {
        workspaceId: number;
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/label-checkpoints/workspace/{workspaceId}/excel/v1',
                path: {
                    workspaceId: workspaceId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Ai Service Config 조회
 * @returns AireviewResponseListWorkspaceAiServiceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceAiConfig(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListWorkspaceAiServiceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-ai-service-configs/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Contract Type 목록 v2
 * @returns AireviewResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getWorkspaceContractTypesV3(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-contract-types/v3',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Contract Type 목록 v2
 * @returns AireviewResponseListContractTypeVo OK
 * @throws ApiError
 */
export function getWorkspaceContractTypesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-contract-types/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Contract Type 목록
 * @returns AireviewResponseListContractTypeVo OK
 * @throws ApiError
 */
export function getWorkspaceContractTypes(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-contract-types/v1',
            }),
            requestConfig,
        ),
    );
}
