/* eslint-disable */
// https://dev2.bhsn.it/api/conhub/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'conhub';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * @deprecated
 * 계약서 전자결재 회수
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function recallContractApproval(
    {
        workflowId,
    }: {
        workflowId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/approvals/workflows/{workflowId}/recall/v1',
                path: {
                    workflowId: workflowId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 해야 할 업무 목록
 * @returns ConhubResponseListTodoReferenceDto OK
 * @throws ApiError
 */
export function listWorksToDo(
    {
        dateStart,
        dateEnd,
    }: {
        /**
         * 조회 시작일
         */
        dateStart?: string;
        /**
         * 조회 종료일
         */
        dateEnd?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListTodoReferenceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/home/works/todo/v1',
                query: {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 현황
 * @returns ConhubResponseListContractStatGroupDto OK
 * @throws ApiError
 */
export function getContractStatGroups(
    {
        dateStart,
        dateEnd,
    }: {
        /**
         * 조회 시작일
         */
        dateStart?: string;
        /**
         * 조회 종료일
         */
        dateEnd?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractStatGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/home/contract-stats/v1',
                query: {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListContractSearchDtoV2 OK
 * @throws ApiError
 */
export function searchContractsV2(
    {
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        contractTypes,
        ownerIds,
        groupIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 소유자
         */
        ownerIds?: Array<number>;
        /**
         * 소유 그룹
         */
        groupIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractSearchDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/search/v2',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    ownerIds: ownerIds,
                    groupIds: groupIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListContractSearchDto OK
 * @throws ApiError
 */
export function searchContracts(
    {
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        contractTypes,
        ownerIds,
        groupIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 소유자
         */
        ownerIds?: Array<number>;
        /**
         * 소유 그룹
         */
        groupIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractSearchDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    ownerIds: ownerIds,
                    groupIds: groupIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponsePageToBeExecutedContractDto OK
 * @throws ApiError
 */
export function searchContractsToBeExecutedV2(
    {
        language,
        contractType,
        searchKeyword,
        page,
        size = 10,
        sort,
    }: {
        language?: string;
        /**
         * 계약 유형
         */
        contractType?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageToBeExecutedContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/search/to-be-executed/v2',
                query: {
                    language: language,
                    contractType: contractType,
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponsePageToBeExecutedContractDto OK
 * @throws ApiError
 */
export function searchContractsToBeExecuted(
    {
        language,
        contractType,
        searchKeyword,
        page,
        size = 10,
        sort,
    }: {
        language?: string;
        /**
         * 계약 유형
         */
        contractType?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageToBeExecutedContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/search/to-be-executed/v1',
                query: {
                    language: language,
                    contractType: contractType,
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListContractWorkflowStepMetaDto OK
 * @throws ApiError
 */
export function getContractWorkflowStepInfos(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseListContractWorkflowStepMetaDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/workflows/meta/step-infos/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListContractWorkflowGroupedStatusMetaDto OK
 * @throws ApiError
 */
export function getContractWorkflowStatusInfos(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseListContractWorkflowGroupedStatusMetaDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/workflows/meta/grouped-status-infos/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크플로우 스텝 멤버 변경
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function changeStepMembers(
    {
        requestBody,
    }: {
        requestBody: ConhubWorkflowStepMemberChangeForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/workflows/steps/members/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 참조자 목록 수정
 * @returns ConhubResponseContractWatcherUpdateDto OK
 * @throws ApiError
 */
export function updateContractWatchers(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractWatcherForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractWatcherUpdateDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/watchers/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 정보 조회 V2
 * @returns ConhubResponseContractInformationDtoV2 contract detail, counterparties, related references, files, watchers
 * @throws ApiError
 */
export function getContractInformationV2(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractInformationDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/v2',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 정보 수정 V2
 * @returns ConhubResponseContractInformationUpdateDtoV2 OK
 * @throws ApiError
 */
export function updateContractInformationV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractInformationUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractInformationUpdateDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 정보 조회
 * @returns ConhubResponseContractInformationDto contract detail, counterparties, related contracts, files, watchers
 * @throws ApiError
 */
export function getContractInformation(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractInformationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 정보 수정
 * @returns ConhubResponseContractInformationUpdateDto OK
 * @throws ApiError
 */
export function updateContractInformation(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractInformationUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractInformationUpdateDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContract(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서명 변경
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function updateContractTitle(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractTitleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/title/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 리마인더 조회
 * @returns ConhubResponseContractReminderDto OK
 * @throws ApiError
 */
export function getContractReminder(
    {
        contractId,
        reminderId,
    }: {
        contractId: number;
        reminderId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractReminderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/reminders/{reminderId}/v1',
                path: {
                    contractId: contractId,
                    reminderId: reminderId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 리마인더 업데이트
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateContractReminder(
    {
        contractId,
        reminderId,
        requestBody,
    }: {
        contractId: number;
        reminderId: number;
        requestBody: ConhubReminderCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/reminders/{reminderId}/v1',
                path: {
                    contractId: contractId,
                    reminderId: reminderId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 리마인더 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContractReminder(
    {
        contractId,
        reminderId,
    }: {
        contractId: number;
        reminderId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/reminders/{reminderId}/v1',
                path: {
                    contractId: contractId,
                    reminderId: reminderId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 관련 참조 목록 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateRelatedReferences(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubRelatedReferencesForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/related-references/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 담당자 변경
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateContractOwner(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractOwnerUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/owner/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 ID 변경 V2
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function updateContractGroupV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractGroupUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/group/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 ID 변경
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function updateContractGroup(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractGroupUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/group/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 희망 검토 기한 변경 V2
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function updateDesiredReviewDeadlineV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractDesiredReviewDeadlineForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/desired-review-deadline/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 희망 검토 기한 변경
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function updateDesiredReviewDeadline(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractDesiredReviewDeadlineForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/desired-review-deadline/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 상대방 조회
 * @returns ConhubResponseContractCounterpartyDto OK
 * @throws ApiError
 */
export function getContractCounterparty(
    {
        contractId,
        counterpartyId,
    }: {
        contractId: number;
        counterpartyId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCounterpartyDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/counterparties/{counterpartyId}/v1',
                path: {
                    contractId: contractId,
                    counterpartyId: counterpartyId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 상대방 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateContractCounterparty(
    {
        contractId,
        counterpartyId,
        requestBody,
    }: {
        contractId: number;
        counterpartyId: number;
        requestBody: ConhubContractCounterpartyForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/{contractId}/counterparties/{counterpartyId}/v1',
                path: {
                    contractId: contractId,
                    counterpartyId: counterpartyId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 상대방 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContractCounterparty(
    {
        contractId,
        counterpartyId,
    }: {
        contractId: number;
        counterpartyId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/counterparties/{counterpartyId}/v1',
                path: {
                    contractId: contractId,
                    counterpartyId: counterpartyId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서상대 북마크 - 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateContractCounterpartyBookmark(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: ConhubContractCounterpartyBookmarkCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/contracts/counterparty-bookmarks/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서상대 북마크 - 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContractCounterpartyBookmark(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/counterparty-bookmarks/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function cancelWorkflow(
    {
        referenceId,
        referenceType,
        workflowId,
        requestBody,
    }: {
        referenceId: number;
        referenceType: 'CONTRACT' | 'WORKFLOW' | 'APPROVAL' | 'CONSULTING' | 'LITIGATION';
        workflowId: number;
        requestBody: ConhubWorkflowCommentFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/workflows/cancel/v1',
                query: {
                    referenceId: referenceId,
                    referenceType: referenceType,
                    workflowId: workflowId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크플로우 스텝 액션 - Tiptap 코멘트
 * @returns ConhubResponseWorkflowStatusDto OK
 * @throws ApiError
 */
export function doWorkflowStepActionV2(
    {
        contractId,
        workflowId,
        stepCode,
        actionId,
        requestBody,
    }: {
        contractId: number;
        workflowId: number;
        stepCode: string;
        actionId: number;
        requestBody: ConhubWorkflowStepActionFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/workflows/{workflowId}/steps/{stepCode}/actions/{actionId}/v2',
                path: {
                    contractId: contractId,
                    workflowId: workflowId,
                    stepCode: stepCode,
                    actionId: actionId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크플로우 생성 - Tiptap 코멘트
 * @returns ConhubResponseWorkflowDto OK
 * @throws ApiError
 */
export function createWorkflowV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractWorkflowFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/workflows/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 현재 워크플로우 스텝 액션 - Tiptap 코멘트
 * @returns ConhubResponseWorkflowStatusDto OK
 * @throws ApiError
 */
export function doCurrentWorkflowStepActionV2(
    {
        contractId,
        stepCode,
        actionId,
        requestBody,
    }: {
        contractId: number;
        stepCode: string;
        actionId: number;
        requestBody: ConhubWorkflowStepActionFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/workflows/current/steps/{stepCode}/actions/{actionId}/v2',
                path: {
                    contractId: contractId,
                    stepCode: stepCode,
                    actionId: actionId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크플로우 이벤트 코멘트 추가 (tiptap)
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function addCommentToCurrentWorkflow(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubWorkflowCommentFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/workflows/current/comments/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자서명 생략
 * @returns ConhubResponseWorkflowStatusDto OK
 * @throws ApiError
 */
export function skipContractSigning(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody?: ConhubWorkflowCommentFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/signings/skipped/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 리마인더 목록 조회
 * @returns ConhubResponseListContractReminderSimpleDto OK
 * @throws ApiError
 */
export function getContractReminderList(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractReminderSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/reminders/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 리마인더 생성
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function createContractReminder(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubReminderCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/reminders/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 첨부파일 추가
 * @returns ConhubResponseContractFileDto OK
 * @throws ApiError
 */
export function addAttachment(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractFileForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractFileDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/files/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주계약서 조회
 * @returns ConhubResponseDocument OK
 * @throws ApiError
 */
export function getDocument(
    {
        contractId,
        revision,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        /**
         * 계약서 버전. 0~
         */
        revision?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseDocument> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/documents/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    revision: revision,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 주계약서 파일 업로드 by JSON
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function uploadDocument(
    {
        contractId,
        requestBody,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        requestBody: ConhubDocument;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/documents/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주계약서 파일 다운로드 by JSON
 * @returns any OK
 * @throws ApiError
 */
export function downloadDocumentByJson(
    {
        contractId,
        fileFormat,
        saveMode,
        requestBody,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        /**
         * 다운로드 포맷
         */
        fileFormat: 'DOCX' | 'PDF';
        /**
         * 다운로드 형태
         */
        saveMode: 'CLEANED' | 'ALL';
        requestBody: ConhubDocument;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/documents/download/by-json/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    fileFormat: fileFormat,
                    saveMode: saveMode,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주계약서 파일 업로드 by File
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function uploadDocumentByFile(
    {
        contractId,
        formData,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        formData?: {
            /**
             * 계약서(file)
             */
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/documents/by-file/v1',
                path: {
                    contractId: contractId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 상대방 전체 조회
 * @returns ConhubResponseListContractCounterpartyDto OK
 * @throws ApiError
 */
export function getContractCounterparties(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractCounterpartyDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/counterparties/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 상대방 생성
 * @returns ConhubResponseContractCounterpartyDto OK
 * @throws ApiError
 */
export function createContractCounterparty(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractCounterpartyForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCounterpartyDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/counterparties/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약 상대방 벌크 추가 - 북마크 ID로 추가
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function createContractCounterparties(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractCounterpartiesByBookmarksForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/counterparties/by-bookmarks/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 체결본 등록 workflow 생성 - Tiptap 코멘트
 * @returns ConhubResponseWorkflowStatusDto OK
 * @throws ApiError
 */
export function createContractCompletionV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractCompletionFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/completions/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결완료 계약서 복사본 저장 (cleaned docx)
 * @returns ConhubResponseString OK
 * @throws ApiError
 */
export function copyContract(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/completions/copy/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 북마크 등록
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function registerBookmark(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/bookmarks/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 북마크 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteBookmark(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/bookmarks/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자결재 생성 v3 - Tiptap 본문
 * @returns ConhubResponseContractApprovalCreatedDto OK
 * @throws ApiError
 */
export function createContractApproval(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractApprovalForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractApprovalCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/approvals/v3',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 전자결재 생성 - Tiptap 본문
 * @returns ConhubResponseContractApprovalCreatedDto OK
 * @throws ApiError
 */
export function createContractApproval1(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubContractApprovalFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractApprovalCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/approvals/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자결재 생략 - Tiptap 코멘트
 * @returns ConhubResponseWorkflowStatusDto OK
 * @throws ApiError
 */
export function skipContractApprovalV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody?: ConhubWorkflowCommentFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/approvals/skipped/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 전자결재 기안
 * @returns ConhubResponseContractApprovalDraftedDto OK
 * @throws ApiError
 */
export function draftContractApproval(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractApprovalDraftedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/approvals/draft/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자결재 의견 추가 - Tiptap 코멘트
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function addContractApprovalCommentV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubWorkflowCommentFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/{contractId}/approvals/current/comments/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서상대 북마크 - 검색
 * @returns ConhubResponsePageContractCounterpartyBookmarkDto OK
 * @throws ApiError
 */
export function searchContractCounterpartyBookmark(
    {
        page,
        size = 30,
        sort,
        counterpartyType,
        keyword,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
        counterpartyType?: 'CORPORATION' | 'CORPORATION_AFFILIATE' | 'CORPORATION_NON_AFFILIATE' | 'SOLE_PROPRIETORSHIP' | 'INDIVIDUAL';
        /**
         * 계약서 북마크 검색 키워드, 법인명/상호명/성명
         */
        keyword?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageContractCounterpartyBookmarkDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/counterparty-bookmarks/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                    counterpartyType: counterpartyType,
                    keyword: keyword,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서상대 북마크 - 생성
 * @returns ConhubResponseContractCounterpartyBookmarkDto OK
 * @throws ApiError
 */
export function createContractCounterpartyBookmark(
    {
        requestBody,
    }: {
        requestBody: ConhubContractCounterpartyBookmarkCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCounterpartyBookmarkDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/counterparty-bookmarks/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 생성 - 표준 계약서로 생성 V3
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractByStandardFormV3(
    {
        requestBody,
    }: {
        requestBody: ConhubContractCreateStandardFormV3;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/by-standard-form/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 생성 - 표준 계약서로 생성 V2
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractByStandardFormV2(
    {
        requestBody,
    }: {
        requestBody: ConhubContractCreateStandardFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/by-standard-form/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 생성 - 파일 업로드로 생성 V3
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractByFileV3(
    {
        requestBody,
    }: {
        requestBody: ConhubContractCreateFileFormV3;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/by-file/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 생성 - 파일 업로드로 생성 V2
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractByFileV2(
    {
        requestBody,
    }: {
        requestBody: ConhubContractCreateFileFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contracts/by-file/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 유형 목록 조회 v2
 * @returns ConhubResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getContractTypesV2(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/{workspaceId}/contracts/types/v2',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 유형 목록 조회
 * @returns ConhubResponseListContractTypeI18nDto OK
 * @throws ApiError
 */
export function getContractTypes(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractTypeI18nDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/{workspaceId}/contracts/types/v1',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 스텝 별 액션 리스트
 * @returns ConhubResponseListWorkflowStepActionDto OK
 * @throws ApiError
 */
export function getActions(
    {
        workflowId,
        stepCode,
        displayType,
    }: {
        workflowId: number;
        stepCode: string;
        displayType?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepActionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/{workflowId}/steps/{stepCode}/actions/v1',
                path: {
                    workflowId: workflowId,
                    stepCode: stepCode,
                },
                query: {
                    displayType: displayType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크 플로우 이벤트 목록 조회 V2 - 코멘트 제외, 마지막 라운드만
 * @returns ConhubResponsePageWorkflowEventDtoV3 OK
 * @throws ApiError
 */
export function getLastRoundEventsWithoutCommentV2(
    {
        referenceId,
        referenceType,
        workflowTypes,
        page,
        size = 20,
        sort,
    }: {
        referenceId: number;
        referenceType: 'CONTRACT' | 'WORKFLOW' | 'APPROVAL' | 'CONSULTING' | 'LITIGATION';
        workflowTypes: Array<'CREATION' | 'REVIEW_REQUEST' | 'REVIEW' | 'APPROVAL' | 'SIGNING' | 'COMPLETION' | 'CONSULTING' | 'UNKNOWN' | 'HANWHAS_REVIEW'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/{referenceType}/{referenceId}/last-round/events/without-comment/v2',
                path: {
                    referenceId: referenceId,
                    referenceType: referenceType,
                },
                query: {
                    workflowTypes: workflowTypes,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크 플로우 이벤트 목록 조회 - 코멘트 제외, 마지막 라운드만
 * @returns ConhubResponsePageWorkflowEventDtoV2 OK
 * @throws ApiError
 */
export function getLastRoundEventsWithoutComment(
    {
        referenceId,
        referenceType,
        workflowTypes,
        page,
        size = 20,
        sort,
    }: {
        referenceId: number;
        referenceType: 'CONTRACT' | 'WORKFLOW' | 'APPROVAL' | 'CONSULTING' | 'LITIGATION';
        workflowTypes: Array<'CREATION' | 'REVIEW_REQUEST' | 'REVIEW' | 'APPROVAL' | 'SIGNING' | 'COMPLETION' | 'CONSULTING' | 'UNKNOWN' | 'HANWHAS_REVIEW'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/{referenceType}/{referenceId}/last-round/events/without-comment/v1',
                path: {
                    referenceId: referenceId,
                    referenceType: referenceType,
                },
                query: {
                    workflowTypes: workflowTypes,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크 플로우 이벤트 목록 조회
 * @returns ConhubResponsePageWorkflowEventDtoV2 OK
 * @throws ApiError
 */
export function getAllWorkflowEvents(
    {
        referenceId,
        referenceType,
        workflowTypes,
        page,
        size = 20,
        sort,
    }: {
        referenceId: number;
        referenceType: 'CONTRACT' | 'WORKFLOW' | 'APPROVAL' | 'CONSULTING' | 'LITIGATION';
        workflowTypes: Array<'CREATION' | 'REVIEW_REQUEST' | 'REVIEW' | 'APPROVAL' | 'SIGNING' | 'COMPLETION' | 'CONSULTING' | 'UNKNOWN' | 'HANWHAS_REVIEW'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/{referenceType}/{referenceId}/events/v1',
                path: {
                    referenceId: referenceId,
                    referenceType: referenceType,
                },
                query: {
                    workflowTypes: workflowTypes,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponsePageWorkflowEventDtoV2 OK
 * @throws ApiError
 */
export function getWorkflowEventsComments(
    {
        referenceId,
        referenceType,
        workflowTypes,
        page,
        size = 20,
        sort,
    }: {
        referenceId: number;
        referenceType: 'CONTRACT' | 'WORKFLOW' | 'APPROVAL' | 'CONSULTING' | 'LITIGATION';
        workflowTypes: Array<'CREATION' | 'REVIEW_REQUEST' | 'REVIEW' | 'APPROVAL' | 'SIGNING' | 'COMPLETION' | 'CONSULTING' | 'UNKNOWN' | 'HANWHAS_REVIEW'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/{referenceType}/{referenceId}/events/comments/v1',
                path: {
                    referenceId: referenceId,
                    referenceType: referenceType,
                },
                query: {
                    workflowTypes: workflowTypes,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크플로우 프리셋 조회
 * @returns ConhubResponseWorkflowPresetDto OK
 * @throws ApiError
 */
export function getWorkflowPreset(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseWorkflowPresetDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/presets/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponsePageWorkflowEventRawDtoV2 OK
 * @throws ApiError
 */
export function getWorkflowEventsRaw(
    {
        referenceId,
        referenceType,
        pageable,
        eventTypes,
    }: {
        referenceId: number;
        referenceType: 'CONTRACT' | 'WORKFLOW' | 'APPROVAL' | 'CONSULTING' | 'LITIGATION';
        pageable: ConhubPageable;
        eventTypes?: Array<
            | 'WORKFLOW_CREATED'
            | 'WORKFLOW_STARTED'
            | 'VIEWED'
            | 'COMMENTED'
            | 'STEP_APPROVED'
            | 'STEP_REJECTED'
            | 'WORKFLOW_CANCELED'
            | 'WORKFLOW_RESET'
            | 'WORKFLOW_RESET_EXPIRED'
            | 'WORKFLOW_SKIPPED'
            | 'WORKFLOW_FINISHED'
        >;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventRawDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workflows/events/raw/v2',
                query: {
                    referenceId: referenceId,
                    referenceType: referenceType,
                    eventTypes: eventTypes,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListGroupUserDto OK
 * @throws ApiError
 */
export function getUsersWithDivisionByUserDisplayName(
    {
        userDisplayName,
    }: {
        /**
         * 검색 할 워크스페이스 사용자 명
         */
        userDisplayName?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListGroupUserDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/with-divisions/v1',
                query: {
                    userDisplayName: userDisplayName,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListSimpleGroupDto OK
 * @throws ApiError
 */
export function getMyGroups(
    {
        groupType,
    }: {
        groupType: 'GROUP' | 'DIVISION';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListSimpleGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/my-groups/{groupType}/v1',
                path: {
                    groupType: groupType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListSimpleGroupDto OK
 * @throws ApiError
 */
export function getMyAllGroups(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseListSimpleGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/my-groups/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseListSimpleGroupDto OK
 * @throws ApiError
 */
export function getMyAuthorizedGroups(
    {
        groupType,
    }: {
        groupType: 'GROUP' | 'DIVISION';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListSimpleGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/my-authorized-groups/{groupType}/v1',
                path: {
                    groupType: groupType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 계약서 목록 조회 V2
 * @returns ConhubResponsePageMyRequestDtoV2 OK
 * @throws ApiError
 */
export function pageMyRequestsV2(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: string;
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageMyRequestDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/v2',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 계약서 목록 조회
 * @returns ConhubResponsePageMyRequestDto OK
 * @throws ApiError
 */
export function pageMyRequests(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageMyRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 계약서 목록 조회 V2
 * @returns ConhubResponsePageMyRequestDtoV2 OK
 * @throws ApiError
 */
export function pageMyGroupRequestsV2(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: string;
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageMyRequestDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/groups/v2',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 계약서 목록 조회
 * @returns ConhubResponsePageMyRequestDto OK
 * @throws ApiError
 */
export function pageMyGroupRequests(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageMyRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/groups/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 계약서 목록 다운로드 V2
 * @returns any OK
 * @throws ApiError
 */
export function downloadMyGroupRequestsV2(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: string;
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/groups/download/v2',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 계약서 목록 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadMyGroupRequests(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/groups/download/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 계약서 목록 다운로드 V2
 * @returns any OK
 * @throws ApiError
 */
export function downloadMyRequestsV2(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: string;
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/download/v2',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 계약서 목록 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadMyRequests(
    {
        createdDateStart,
        createdDateEnd,
        language,
        progressStatus,
        searchKeyword,
        contractTypes,
        userIds,
        groupIds,
        assignedRoles,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 계약서 언어
         */
        language?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 진행 상태
         */
        progressStatus?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 역할 구분
         */
        assignedRoles?: Array<'CREATOR' | 'OWNER' | 'REVIEW_REQUESTER' | 'REVIEWER' | 'REVIEW_AGREER' | 'WATCHER'>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT' | 'DESIRED_REVIEW_DEADLINE';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-requests/download/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    language: language,
                    progressStatus: progressStatus,
                    searchKeyword: searchKeyword,
                    contractTypes: contractTypes,
                    userIds: userIds,
                    groupIds: groupIds,
                    assignedRoles: assignedRoles,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 문서 미리보기 (file to document)
 * @returns ConhubResponseDocument OK
 * @throws ApiError
 */
export function getDocumentByFile(
    {
        fileKey,
        language,
    }: {
        /**
         * 계약서 파일 key
         */
        fileKey: string;
        /**
         * 언어
         */
        language?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseDocument> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/documents/by-file/v1',
                query: {
                    language: language,
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크플로우 스텝 목록 조회
 * @returns ConhubResponseListWorkflowStepDto OK
 * @throws ApiError
 */
export function getWorkflowSteps(
    {
        contractId,
        workflowId,
    }: {
        contractId: number;
        workflowId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/{workflowId}/steps/v1',
                path: {
                    contractId: contractId,
                    workflowId: workflowId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크플로우 스텝 프리셋 조회
 * @returns ConhubResponseListWorkflowStepDefDto OK
 * @throws ApiError
 */
export function getWorkflowStepDefsFromPreset(
    {
        contractId,
        workflowType,
    }: {
        contractId: number;
        /**
         * 워크플로우 타입
         */
        workflowType: 'CREATION' | 'REVIEW_REQUEST' | 'REVIEW' | 'APPROVAL' | 'SIGNING' | 'COMPLETION' | 'CONSULTING' | 'UNKNOWN' | 'HANWHAS_REVIEW';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepDefDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/step-preset/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    workflowType: workflowType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크 플로우 이벤트 목록 조회 v3 - 코멘트 제외, 마지막 라운드만
 * @returns ConhubResponsePageWorkflowEventDtoV2 OK
 * @throws ApiError
 */
export function getContractWorkflowEventsV3(
    {
        contractId,
        page,
        size = 20,
        sort,
    }: {
        contractId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/events/v3',
                path: {
                    contractId: contractId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크 플로우 이벤트 목록 조회
 * @returns ConhubResponsePageWorkflowEventDtoV2 OK
 * @throws ApiError
 */
export function getContractWorkflowEventsV2(
    {
        contractId,
        page,
        size = 20,
        sort,
    }: {
        contractId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/events/v2',
                path: {
                    contractId: contractId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크 플로우 진행내역 조회
 * @returns ConhubResponsePageWorkflowEventSimpleDto OK
 * @throws ApiError
 */
export function getContractWorkflowEventsWithOnlySteps(
    {
        contractId,
        page,
        size = 20,
        sort,
    }: {
        contractId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/events/steps/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크 플로우 코멘트 목록 조회
 * @returns ConhubResponsePageWorkflowEventDtoV2 OK
 * @throws ApiError
 */
export function getContractWorkflowCommentEvents(
    {
        contractId,
        page,
        size = 20,
        sort,
    }: {
        contractId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageWorkflowEventDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/events/comments/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 현재 워크플로우 스텝 액션 목록 조회
 * @returns ConhubResponseListWorkflowStepActionDto OK
 * @throws ApiError
 */
export function getCurrentWorkflowStepActionsByStepCode(
    {
        contractId,
        stepCode,
        displayType,
    }: {
        contractId: number;
        stepCode: string;
        /**
         * 표시 타입 (defaultValue: null)
         */
        displayType?: string | null;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepActionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/current/steps/{stepCode}/actions/v1',
                path: {
                    contractId: contractId,
                    stepCode: stepCode,
                },
                query: {
                    displayType: displayType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 계약서 워크플로우 스텝 목록 조회
 * @returns ConhubResponseListWorkflowStepDto OK
 * @throws ApiError
 */
export function getCurrentWorkflowSteps(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/current/steps/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 현재 워크플로우 스텝 액션 목록 조회
 * @returns ConhubResponseListWorkflowStepActionDto OK
 * @throws ApiError
 */
export function getCurrentWorkflowStepActions(
    {
        contractId,
        displayType,
    }: {
        contractId: number;
        /**
         * 표시 타입 (defaultValue: null)
         */
        displayType?: string | null;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepActionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/current/steps/actions/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    displayType: displayType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 계약서 각 워크플로우 진행 상태 조회(작성, 검토, 결재 및 서명, 체결)
 * @returns ConhubResponseListContractWorkflowStepGroupDto OK
 * @throws ApiError
 */
export function getCurrentWorkflowStepGroups(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractWorkflowStepGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/current/step-groups/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 계약서 워크플로우 라운드 조회
 * @returns ConhubResponseInteger OK
 * @throws ApiError
 */
export function getCurrentWorkflowRound(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseInteger> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflows/current/round/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 워크플로우 프로세스 상태 조회
 * @returns ConhubResponseWorkflowProcessStatusDto OK
 * @throws ApiError
 */
export function getContractWorkflowProcessStatus(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWorkflowProcessStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/workflow-processes/current/status/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 정보 조회 V3
 * @returns ConhubResponseContractInformationDtoV3 contract detail, counterparties, related references, files, watchers
 * @throws ApiError
 */
export function getContractInformationV3(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractInformationDtoV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/v3',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자서명 정보 조회
 * @returns ConhubResponseSigningDto OK
 * @throws ApiError
 */
export function getContractSigning(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseSigningDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/signings/current/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자서명 취소
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function cancelContractSigning(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody?: ConhubWorkflowCommentFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/signings/current/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서에 대한 권한 목록 조회
 * @returns ConhubResponseListString OK
 * @throws ApiError
 */
export function listMyPermissions(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/permissions/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Word 주계약서 수정 상태 조회
 * @returns ConhubResponseWordContractEditStatusDto OK
 * @throws ApiError
 */
export function getWordDocumentEditStatus(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseWordContractEditStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/documents/word/edit-status/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Word 주계약서 Path 제공
 * @returns ConhubResponseContractAccessInfoDto OK
 * @throws ApiError
 */
export function getWordDocumentAccessPath(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractAccessInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/documents/word/access-path/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 주계약서 파일 리비전 목록 조회
 * @returns ConhubResponseContractFileRevisionDto OK
 * @throws ApiError
 */
export function getFileRevisions(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/documents/revisions/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 체결본 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadExecutedCopy(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/documents/executed/download/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 주계약서 파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadDocument(
    {
        contractId,
        fileFormat,
        saveMode,
        revision,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        /**
         * 다운로드 포맷
         */
        fileFormat: 'DOCX' | 'PDF';
        /**
         * 다운로드 형태
         */
        saveMode: 'CLEANED' | 'ALL';
        /**
         * 계약서 버전. 0~
         */
        revision?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/documents/download/v1',
                path: {
                    contractId: contractId,
                },
                query: {
                    revision: revision,
                    fileFormat: fileFormat,
                    saveMode: saveMode,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseContractDto OK
 * @throws ApiError
 */
export function getBasicContractV2(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/basic/v2',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ConhubResponseContractDto OK
 * @throws ApiError
 */
export function getBasicContract(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/basic/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자결재 정보 조회 - Tiptap 본문
 * @returns ConhubResponseContractApprovalDtoV2 OK
 * @throws ApiError
 */
export function getContractApprovalV2(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseContractApprovalDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/{contractId}/approvals/current/v2',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 나의 관련 계약 목록 조회 V2
 * @returns ConhubResponsePageMyRelatedContractDtoV2 OK
 * @throws ApiError
 */
export function listMyRelatedContractsV2(
    {
        createdAtStart,
        createdAtEnd,
        contractTitle,
        owners,
        page,
        size = 20,
        sort,
    }: {
        createdAtStart?: string;
        createdAtEnd?: string;
        contractTitle?: string;
        owners?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageMyRelatedContractDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/my-related/v2',
                query: {
                    createdAtStart: createdAtStart,
                    createdAtEnd: createdAtEnd,
                    contractTitle: contractTitle,
                    owners: owners,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 메타 조회 V2
 * @returns ConhubResponseMapStringListObject OK
 * @throws ApiError
 */
export function getContractMetaV21(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/meta/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스별 계약서 메타 조회
 * @returns ConhubResponseMapStringListObject OK
 * @throws ApiError
 */
export function getContractMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 북마크 조회
 * @returns ConhubResponsePageContractDto OK
 * @throws ApiError
 */
export function pageMyContractBookmarks(
    {
        page,
        size = 30,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contracts/bookmarks/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 나의 배정해야 할 의뢰 조회 V2
 * @returns ConhubResponseListAssignmentRequestDtoV2 OK
 * @throws ApiError
 */
export function getToBeAssignedContractWorkflowStepV2(
    {
        contractLanguages,
        workflowCreatedBys,
        contractTypes,
    }: {
        /**
         * 계약서 언어
         */
        contractLanguages?: Array<string>;
        /**
         * 의뢰자 id
         */
        workflowCreatedBys?: Array<number>;
        /**
         * 계약서 코드
         */
        contractTypes?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListAssignmentRequestDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/assignments/my-related/to-be-assigned/v2',
                query: {
                    contractLanguages: contractLanguages,
                    workflowCreatedBys: workflowCreatedBys,
                    contractTypes: contractTypes,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 나의 배정해야 할 의뢰 조회
 * @returns ConhubResponseListAssignmentRequestDto OK
 * @throws ApiError
 */
export function getToBeAssignedContractWorkflowStep(
    {
        contractLanguages,
        workflowCreatedBys,
        contractTypes,
    }: {
        /**
         * 계약서 언어
         */
        contractLanguages?: Array<'KOREAN' | 'ENGLISH' | 'ETC'>;
        /**
         * 의뢰자 id
         */
        workflowCreatedBys?: Array<number>;
        /**
         * 계약서 코드
         */
        contractTypes?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListAssignmentRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/assignments/my-related/to-be-assigned/v1',
                query: {
                    contractLanguages: contractLanguages,
                    workflowCreatedBys: workflowCreatedBys,
                    contractTypes: contractTypes,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사람 별 현재 업무 현황 조회
 * @returns ConhubResponseListWorkflowStepCountDto OK
 * @throws ApiError
 */
export function getCurrentStepStatusCountByMember(
    {
        userIds,
        dateEnd,
        dateStart,
    }: {
        /**
         * 멤버 유저 ID
         */
        userIds?: Array<number>;
        /**
         * 조회 종료일(default: 현재)
         */
        dateEnd?: string;
        /**
         * 조회 시작일(default: dateEnd - 12months)
         */
        dateStart?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowStepCountDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/assignments/current-status/count-by-member/v1',
                query: {
                    userIds: userIds,
                    dateEnd: dateEnd,
                    dateStart: dateStart,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 멤버별 할당받은 워크플로우 현황 조회
 * @returns ConhubResponseListContractWorkflowStepDto OK
 * @throws ApiError
 */
export function getContractWorkflowStep(
    {
        stepIds,
    }: {
        stepIds: Array<number>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractWorkflowStepDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/assignments/current-status/by-step-id/v1',
                query: {
                    stepIds: stepIds,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 전자결재 조회 - TipTap 본문
 * @returns ConhubResponseApprovalDtoV2 OK
 * @throws ApiError
 */
export function getApprovalV2(
    {
        approvalId,
    }: {
        approvalId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseApprovalDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/approvals/{approvalId}/v2',
                path: {
                    approvalId: approvalId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 전자결재 조회
 * @returns ConhubResponseApprovalDto OK
 * @throws ApiError
 */
export function getApproval(
    {
        approvalId,
    }: {
        approvalId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseApprovalDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/approvals/{approvalId}/v1',
                path: {
                    approvalId: approvalId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 전자결재 목록 조회
 * @returns ConhubResponsePageApprovalWithStatusDto OK
 * @throws ApiError
 */
export function pageApprovals(
    {
        pageable,
        dateStart,
        dateEnd,
        stepTypes,
        finishTypes,
        creators,
        searchKeyword,
    }: {
        pageable: ConhubPageable;
        /**
         * 조회 시작일
         */
        dateStart?: string;
        /**
         * 조회 종료일
         */
        dateEnd?: string;
        /**
         * 구분
         */
        stepTypes?: Array<string>;
        /**
         * 결재 상태
         */
        finishTypes?: Array<string>;
        /**
         * 기안자 ID 목록
         */
        creators?: Array<number>;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponsePageApprovalWithStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/approvals/v1',
                query: {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    stepTypes: stepTypes,
                    finishTypes: finishTypes,
                    creators: creators,
                    searchKeyword: searchKeyword,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 현재 워크플로우 취소 - Tiptap 코멘트
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function cancelCurrentWorkflowV2(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ConhubWorkflowCancelFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/workflows/current/v2',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 첨부파일 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteAttachment(
    {
        contractId,
        fileId,
    }: {
        contractId: number;
        fileId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/files/{fileId}/v1',
                path: {
                    contractId: contractId,
                    fileId: fileId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 전자결재 회수
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function withdrawContractApproval(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/contracts/{contractId}/approvals/current/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 계약서 타입 조회
 * @returns ConhubResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getContractTypesV21(
    {
        workspaceId,
    }: {
        workspaceId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/contracts/contract-types/v2',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 계약서 타입 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function putContractTypesV2(
    {
        requestBody,
    }: {
        requestBody: ConhubWorkspaceContractTypeUpdateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/contracts/contract-types/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 계약서 타입 조회
 * @returns ConhubResponseListContractTypeI18nIsoCodeVo OK
 * @throws ApiError
 */
export function getContractTypes1(
    {
        workspaceId,
    }: {
        workspaceId?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListContractTypeI18nIsoCodeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/contracts/contract-types/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 계약서 타입 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function putContractTypes(
    {
        requestBody,
    }: {
        requestBody: ConhubWorkspaceContractTypeUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/contracts/contract-types/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크플로우 프리셋 조회
 * @returns ConhubResponseListWorkflowOption OK
 * @throws ApiError
 */
export function getPresets(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponseListWorkflowOption> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workflow-presets/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크플로우 프리셋 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updatePresets(
    {
        workspaceId,
        requestBody,
    }: {
        workspaceId: number;
        requestBody: ConhubSuperAdminWorkflowPresetForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ConhubResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/workflow-presets/v1',
                query: {
                    workspaceId: workspaceId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크플로우 프리셋 사용 가능 옵션 목록
 * @returns ConhubResponseListEnumDto OK
 * @throws ApiError
 */
export function getAvailableOptions(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseListEnumDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workflow-presets/meta/options/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 계약서 메타 조회 V2
 * @returns ConhubResponseMapStringListObject OK
 * @throws ApiError
 */
export function getContractMetaV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/contracts/meta/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 계약서 메타 조회
 * @returns ConhubResponseMapStringListObject OK
 * @throws ApiError
 */
export function getGlobalContractMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<ConhubResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/contracts/meta/v1',
            }),
            requestConfig,
        ),
    );
}
